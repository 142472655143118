<template>
    <!--种植状态展示-->
    <div class="plant-status">
        <a-space>
            <a-button type="primary" size="small"
                      v-if="scene == 'add' || scene == 'all'"
                      @click="showAddOrEdit(0)">添加状态
            </a-button>
            <a-button type="primary" size="small"
                      v-if="scene == 'all'"
                      @click="showPlantStateList">状态跟踪
            </a-button>
        </a-space>

        <a-modal title="添加状态" width="600px"
                 v-model:visible="pstState.show"
                 @ok="savePlantStatus"
                 @cancel="pstState.show = false"
        >
            <a-form :label-col="{span:4}" :wrapper-col="{span:16}">
                <a-form-item label="成熟度">
                    <a-select v-model:value="pstState.form.maturity" placeholder="请选择成熟度">
                        <a-select-option value="幼苗期">幼苗期</a-select-option>
                        <a-select-option value="生长期">生长期</a-select-option>
                        <a-select-option value="成熟期">成熟期</a-select-option>
                        <a-select-option value="枯萎期">枯萎期</a-select-option>
                    </a-select>
                </a-form-item>
                <template v-if="pstState.form.server_content">
                    <a-form-item v-for="(item,index) in pstState.form.server_content"
                                 :label="item.name"
                                 :key="index">
                        <a-radio-group v-model:value="item.is_need">
                            <a-radio :value='1'>需要</a-radio>
                            <a-radio :value="0">不需要</a-radio>
                        </a-radio-group>
                    </a-form-item>
                </template>
                <a-form-item label="轮播图">
                    <kd-img-select :mutil="true"
                                   :src="pstState.form.src"
                                   @success="e=>pstState.form.src=e">
                    </kd-img-select>
                </a-form-item>
                <a-form-item label="预估产量">
                    <a-input placeholder="预估产量" v-model:value="pstState.form.yield" addon-after="kg"></a-input>
                </a-form-item>
                <a-form-item label="文字说明">
                    <a-textarea v-model:value="pstState.form.description" placeholder="请输入内容" :rows="4" />
                </a-form-item>
            </a-form>
        </a-modal>

        <a-modal title="状态跟踪" width="800px"
                 v-model:visible="pstState.showList"
                 @close="pstState.showList = false"
        >
            <a-timeline>
                <a-timeline-item v-for="(item,index) in pstState.list" :key="index">
                    <div>{{item.description}}</div>
                    <div class="cpl-imgs">
                        <a-image style="width: 50px;height: 50px;margin-right: 12px;"
                                 v-for="(val,ind) in item.src_show" :key='ind' :src="val" />
                    </div>
                    <a-space>
                        <a-button size="small" type="link" @click="showAddOrEdit(item)">编辑</a-button>
                        <a-button size="small" type="link" @click="deletePlantStatus(item)">删除</a-button>
                    </a-space>
                </a-timeline-item>
            </a-timeline>
        </a-modal>

    </div>
</template>

<script>
import { usePlantStatusTail } from '@/models/land'
export default {
    name: "com-plant-status.vue",
    props:{
        scene:{
            type:String,
            default:"add",  //add表示仅添加  all //添加状态+状态跟踪
        },
        landId:{            //土地id
            type:[Number,String],
            default:0
        },
        ids:{               //状态ID
            type:[Object,Number],
        }
    },
    setup(props){

        let pstState = usePlantStatusTail({landId:props.landId,ids:props.ids,scene:props.scene})

        return{ ...pstState }
    }
}
</script>

<style scoped>

</style>
