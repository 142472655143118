<template>
	<div id="cpm_landOrderDetail" class="el-content" v-if="detail.order">
		<div class="plrod-header mb12">
			<div>
				<span class="order-number">单号:{{detail.order.order_number}}</span>
				<a-tag :color="$status.getLandOrderStatus(detail.order,true)">
                    {{$status.getLandOrderStatus(detail.order)}}
                </a-tag>
			</div>
			<a-space>
				<a-button  size="small" @click="changeLandOrderState('confirm',detail.order.id)"
                    v-has="{action:'wxapp_land_order_stop',plat:isShop}"
                    v-if="$status.getLandOrderOperation(detail.order,'stop')">终止租赁
                </a-button>
				<a-button  size="small" @click="changeLandOrderState('refund_yes_order',detail.order.id)"
                    v-has="{action:'wxapp_land_order_refund',plat:isShop}"
                    v-if="$status.getLandOrderOperation(detail.order,'refund')">退款
                </a-button>
				<a-button  size="small" @click="changeLandOrderState('cancel',detail.order.id)"
                    v-has="{action:'wxapp_land_order_cancel',plat:isShop}"
                    v-if="$status.getLandOrderOperation(detail.order,'cancel')">取消订单
                </a-button>
				<kd-set-remark
					v-has="{action:'wxapp_land_order_remark',plat:isShop}"
					remark-type="land-order"
					postApi="land2_setLandOrder"
					@success="()=>{getLandOrderDetail()}"
					:remarkValue="detail.order.manager_remark"
					:params="{
						id:detail.order.id,
					}"
				>
				</kd-set-remark>
			</a-space>
		</div>

		<a-row>
		    <a-col :span="8">
				<p><span class="f13">下单用户：</span>{{detail.order.user_name}} {{detail.order.phone}}</p>
				<p><span class="f13">创建时间：</span>{{detail.order.create_time}}</p>
				<p><span class="f13">生效日期：</span>{{detail.order.create_time}}</p>
				<p><span class="f13">订单总价：</span><span class="kdred">{{detail.order.total_price}}元</span></p>

			</a-col>
		    <a-col :span="8">
				<p v-if="detail.spec"><span class="f13">租赁地块：</span>{{detail.spec.land_num}}</p>
				<p v-if="detail.spec"><span class="f13">租赁面积：</span>{{detail.spec.area}}</p>
				<p v-if="detail.ser">
					<span class="f13">服务类型：</span>
					<a-tag color="#FF0066">{{detail.ser.name}}</a-tag>
					<a-button type="link"
                        @click="showEditService"
                        v-has="{action:'wxapp_land_order_update_ser',plat:isShop}">修改
                    </a-button>
				</p>
				<p>
					<span class="f13">巡检员：</span>
					<span v-if="detail.order.inspector_id == 0">未分配</span>
					<span v-else>{{detail.order.inspector.name}}</span>
					<a-button type="link" @click="showEditPolling">修改</a-button>
				</p>
			</a-col>
		    <a-col :span="8">
				<p v-if="detail.spec">
					<span class="f13">到期时间：</span>{{detail.order.expire_date}}
					<a-button
                        v-has="{action:'wxapp_land_order_update_expire_time',plat:isShop}"
                        type="link"
                        @click="eetState.show = true">修改
                    </a-button>
				</p>
				<p v-if="detail.spec" v-has="{action:'wxapp_order_cashback',plat:isShop}" >
                    <com-cashback-status :order-id="detail.order.id" :order-type="2"></com-cashback-status>
				</p>
				<p v-if="detail.spec"><span class="f13">备注：</span>{{detail.order.remark}}</p>
				<p v-if="detail.spec"><span class="f13">商家备注：</span>{{detail.order.manager_remark}}</p>
				<p v-if="detail.spec">
					<span class="f13">国标设备：</span>
					<com-select-gbdevice :order="detail.order" :set-type="4"></com-select-gbdevice>
				</p>
			</a-col>
		</a-row>
		<a-tabs defaultActiveKey @change="changeTab">
			<a-tab-pane key="1" tab="种植管理">
				<com-plant-list :list="detail.plant" @success="()=>getLandOrderDetail()"></com-plant-list>
			</a-tab-pane>
			<a-tab-pane key="2" tab="配送订单">
				<com-delivery-list :list="ldoState.list"
                                   sence="detail"
                                   @success="()=>getLandDeliveryOrderList(1,999)">
                </com-delivery-list>
			</a-tab-pane>
			<a-tab-pane key="3" tab="种植订单">
				<a-table :pagination="false" row-key="id" :data-source="poState.list" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'订单编号',dataIndex:'order_number'},
					{title:'订单总价',dataIndex:'total_price',slots:{customRender:'total_price'}},
					{title:'订单状态',dataIndex:'status',slots:{customRender:'status'}},
					{title:'种植状态',dataIndex:'is_plant',slots:{customRender:'is_plant'}},
					{title:'支付方式',dataIndex:'pay_method',slots:{customRender:'pay_method'}},
					{title:'创建时间',dataIndex:'create_time'},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]" >
				   <template #total_price="{record}">
					   <span class="kdred">{{record.total_price}}</span>
				   </template>
				   <template #status="{record}">
					   <a-tag :color="$status.getPlantOrderStatusBg(record)">
                           {{$status.getPlantOrderStatus(record)}}
                        </a-tag>
				   </template>
				   <template #is_plant="{record}">
					   <a-tag :color="record.is_plant == 1 ?'#0099FF':'#999'">
                           {{ record.is_plant ==1 ?'已种植':'未种植'}}
                        </a-tag>
				   </template>
				   <template #pay_method="{record}">
					   <span>{{$util.payMethod(record.pay_method)}}</span>
				   </template>
				   <template #action="{record}">
					   <a-button type="danger"
                        v-has="{action:'wxapp_land_plant_refund',plat:isShop}"
                        v-if="record.pay_status == 1 && record.is_refund !=2 && record.is_confirm !=1"
                        @click="refundPlantOrder(record.id)">退款
                       </a-button>
				   </template>
				</a-table>
			</a-tab-pane>
			<a-tab-pane key="4" tab="续费/升级订单">
				<a-table :pagination="false" row-key="id" :data-source="lroState.list" :columns="[
					{title:'ID',dataIndex:'id'},
					{title:'订单编号',dataIndex:'order_number'},
					{title:'订单类型',dataIndex:'type',slots:{customRender:'type'}},
					{title:'订单操作前信息',dataIndex:'before',slots:{customRender:'before'}},
					{title:'订单操作后信息',dataIndex:'after',slots:{customRender:'after'}},
					{title:'订单金额',dataIndex:'total_price',slots:{customRender:'total_price'}},
					{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
				]" >
					<template #type="{record}">
						<a-tag :color="record.order_type == 1 ?'#00CC66':'#00CCFF'">{{record.order_type == 1 ? '土地升级订单':'土地续费订单'}}</a-tag>
					</template>
					<template #before="{record}">
						<div  v-if="record.old_ser"><span class="f12">土地服务：</span>{{ record.old_ser.name }}</div>
						<div><span class="f12">到期时间：</span>{{ record.start_at }}</div>
					</template>
					<template #after="{record}">
						<div  v-if="record.new_ser"><span class="f12">土地服务：</span>{{ record.new_ser.name }}</div>
						<div><span class="f12">到期时间：</span>{{ record.expire_at }}</div>
					</template>
					<template #total_price="{record}">
						<div><span class="f12">订单金额：</span><span class="kdred">{{ record.total_price }}</span></div>
						<a-tag color="#999" v-if="record.pay_status==0">未支付</a-tag>
						<a-tag color="#FF3366" v-if="record.is_refund==2">已退款</a-tag>
					</template>
					<template #action="{record}">
						<a-space>
                            <com-pay-result :id="record.id" :type="record.order_type == 1 ? 8:9"></com-pay-result>
							<a-button
								v-has="{action:'wxapp_land_order_upgrade_refund',plat:isShop}"
								type="danger"
								v-if="record.pay_status == 1 && record.is_refund == 0 && $status.getLandOrderOperation(detail.order,'refund') "
								@click="refundLandRenewOrder(record.id)">订单退款
							</a-button>
						</a-space>
					</template>
				</a-table>
			</a-tab-pane>
			<a-tab-pane key="5" tab="订单日志">
				<a-timeline style="margin-left: 24px;">
				    <a-timeline-item v-for="(item, index) in orderLog" :key="index">
						{{item.content}}
						<p class="f12">{{item.create_time}}</p>
					</a-timeline-item>
				</a-timeline>
			</a-tab-pane>
			<a-tab-pane key="6" tab="巡检记录">
				<a-timeline style="margin-left: 24px;">
				    <a-timeline-item v-for="(val, index) in pollingRecord" :key="index">
						<div>巡检对象：{{val.title}}</div>
						<div>成熟度：{{val.maturity}}</div>
						<div v-for="(v,i) in val.server_content" :key="i">{{v.name}}：{{v.is_need==1?'需要':'不需要'}}</div>
						<div class="polling-img">
							<a-image style="width: 60px;margin-left: 4px;" v-for="(item,index) in val.images" :key="index" :src="item" />
						</div>
						<p class="f12">{{val.create_time}}</p>
					</a-timeline-item>
				</a-timeline>
			</a-tab-pane>
			<template #tabBarExtraContent>
				<a-button @click="showAddLandDeliveryOrder(detail)" type="primary">
                    <i class="ri-add-line"></i>新增配送
                </a-button>
			</template>
		</a-tabs>
		<a-modal title="新增配送"
                 v-model:visible="aldoState.show"
                 width="600px"
                 @ok="saveLandDeliveryOrder(detail.order.id)"
                 @cancel="aldoState.show = false"
        >
		    <a-form :label-col="{span:4}" :wrapper-col="{span:16}">
		    	<a-form-item label="收货姓名">
		    		<a-input v-model:value="aldoState.form.name"></a-input>
		    	</a-form-item>
		    	<a-form-item label="收货电话">
		    		<a-input v-model:value="aldoState.form.phone"></a-input>
		    	</a-form-item>
		    	<a-form-item label="收货地址">
		    		<a-input v-model:value="aldoState.form.address" disabled></a-input>
		    	</a-form-item>
		    	<a-form-item label="预计配送时间">
					<a-date-picker type="date" v-model:value="aldoState.form.pre_delivery_time" placeholder="预计配送时间"/>
		    	</a-form-item>
		    	<a-form-item label="配送重量">
		    		<a-input v-model:value="aldoState.form.delivery_weight" addon-after="kg"></a-input>
		    	</a-form-item>
		    	<a-form-item label=" 配送物品说明">
		    		<a-input v-model:value="aldoState.form.item_name"></a-input>
		    	</a-form-item>
		    	<a-form-item label=" 总价值">
		    		<a-input v-model:value="aldoState.form.item_price"></a-input>
		    	</a-form-item>
		    	<a-form-item label="包裹数量(件)">
		    		<a-input v-model:value="aldoState.form.item_quantity"></a-input>
		    	</a-form-item>
		    </a-form>
		</a-modal>

        <a-modal title="更改服务类型"
                 width="500px"
                 v-model:visible="elosState.show"
                 @ok="saveLandOrderService(detail.order.id)"
                 @cancel="elosState.show = false"
        >
			<a-select v-model:value="elosState.ser_id" placeholder="请选择服务类型" style="width: 400px;">
			    <a-select-option v-for="(item,index) in elosState.list" :key="index" :value="item.id">
                    {{item.name}}
                </a-select-option>
			</a-select>
		</a-modal>

        <a-modal title="分配巡检员" width="500px"
                 v-model:visible="elopState.show"
                 @ok="savePolling(detail.order.id)"
                 @cancel="elopState.show = false"
        >
			<a-select v-model:value="elopState.inspector_id" placeholder="请选择巡检员" style="width: 400px;">
			    <a-select-option v-for="(item,index) in elopState.list" :key="index" :value="item.id">
                    {{item.name}}
                </a-select-option>
			</a-select>
		</a-modal>

		<a-modal title="租赁到期时间" width="500px"
                 v-model:visible="eetState.show"
                 @ok="saveExpireTime(detail.order.id)"
                 @cancel="eetState.show = false"
        >
			<a-date-picker style="width: 400px;" v-model:value="eetState.expire_date" placeholder="选择租赁到期时间"/>
		</a-modal>
	</div>
</template>

<script>
import kdSetRemark from '@/components/miniapp/com-set-remark.vue'
import comCashbackStatus from '@/components/miniapp/com-cashback-status.vue'
import comDeliveryList from '@/components/layout/land/com-delivery-list.vue'
import comPlantList from '@/components/layout/land/com-plant-list.vue'
import comPayResult from '@/components/miniapp/com-pay-result.vue'
import comSelectGbdevice from '@/components/layout/live/com-select-gbdevice.vue'
import { reactive, toRefs } from 'vue'
import { useRoute } from 'vue-router'
import landModel from '@/api/land.js'

import {
    usePlantOrder,
    useLandRenewOrder,
    useLandOrder,
    useEditLandOrderService,
    useEditLandOrderPolling,
    useEditExpireTime,
    useLandDeliveryOrder,
    useAddLandDeliveryOrder
} from '@/models/land'

export default{
	name:'page-land-rent-order-detail',
	components:{
		kdSetRemark,
		comPlantList,
		comDeliveryList,
        comCashbackStatus,
        comPayResult,
		comSelectGbdevice
	},
	props:{
		isShop:Number
	},
    setup(){
        const _d = reactive({
            detail:{},
            orderLog:[],
            pollingRecord:[],
        })
        const opt = useRoute().query
        if( opt.order_id ) getLandOrderDetail()
        function getLandOrderDetail(){
            landModel.getLandOrderDetail(opt.order_id,res=>{
                _d.detail = res
            })
        }

        //种植订单
        let {
            poState,
            getPlantOrderList,
            refundPlantOrder
        } = usePlantOrder(null,{order_id: opt.order_id})

        //续费升级订单
        let {
            lroState,
            getLandRenewOrder,
            refundLandRenewOrder
        } = useLandRenewOrder({land_order_id:opt.order_id})

        //土地订单操作

        let { changeLandOrderState } = useLandOrder(null,getLandOrderDetail)

        //服务修改操作
        let elosState = useEditLandOrderService(getLandOrderDetail)

        //巡检员修改
        let elopState = useEditLandOrderPolling(getLandOrderDetail)

        //更新过期时间
        let { eetState,saveExpireTime } = useEditExpireTime(getLandOrderDetail)

        //配送订单
        let { ldoState,getLandDeliveryOrderList } = useLandDeliveryOrder({order_id:opt.order_id},getLandOrderDetail )

        //添加配送
        let aldoState = useAddLandDeliveryOrder(getLandDeliveryOrderList)

        function changeTab(e){
            if( e == 2) getLandDeliveryOrderList(1,999)
            if( e == 3) getPlantOrderList(1,999)
            if( e == 4) getLandRenewOrder()
            if( e == 5 ) {
                landModel.getLandOrderLog(opt.order_id,res=>{
                    _d.orderLog = res
                })
            }
            if( e == 6 ) {
                landModel.getLandOrderInspectorLog(opt.order_id,res=>{
                    _d.pollingRecord = res
                })
            }
        }

        return {
            ...toRefs(_d),
            changeTab,
			getLandOrderDetail,

            poState,
            refundPlantOrder,

            lroState,
            refundLandRenewOrder,
            changeLandOrderState,

            ...elosState,

            ...elopState,
            eetState,saveExpireTime,

            ldoState,
            getLandDeliveryOrderList,
            ...aldoState
        }
    }
}
</script>

<style lang="scss">
	.plrod-header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		.order-number{
			font-size: 20px;
			font-weight: bold;
			margin-right: 12px;
			position: relative;
			top: 2px;
		}
	}
</style>
