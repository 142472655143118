<template>
	<div class="com-plant-list">
		<a-space class="mb12" v-if="selection">
			<a-button @click="changePlantStatus('sau',pks)">播种</a-button>
			<a-button @click="changePlantStatus('xiao',pks)">幼苗</a-button>
			<a-button @click="changePlantStatus('up',pks)">生长</a-button>
			<com-plant-output-set
				:ids="pks"
				@success="callbackFun"
			>
				<a-button>成熟</a-button>
			</com-plant-output-set>

			<a-button @click="changePlantStatus('complete',pks)">已完成</a-button>
			<a-button @click="changePlantStatus('withered',pks)">枯萎</a-button>
		</a-space>
		<a-table :pagination="false" row-key="id" :data-source="list" :columns="[
			{title:'ID',dataIndex:'id'},
			{title:'种子名称',dataIndex:'name'},
			{title:'单价/面积',dataIndex:'area',slots:{customRender:'area'}},
			{title:'时间',dataIndex:'time',slots:{customRender:'time'}},
			{title:'当前状态',dataIndex:'status',slots:{customRender:'status'}},
			{title:'操作',dataIndex:'action',slots:{customRender:'action'}},
		]" :row-selection="selection ? { selectedRowKeys: pks, onChange:(e)=>{pks = e}  } : null">
			<template #area="{record}">
				<div><span class="f12">单价/面积：</span>{{ record.price }}元/{{ record.area}}㎡</div>
				<div>
                    <span class="f12">总价单价：</span>
                    <span class="kdred">{{getTotalPrice(record.area,record.price)}}元</span>
                </div>
			</template>
			<template #time="{record}">
				<div><span class="f12">播种时间：</span>{{ record.plant_time || '-'}}</div>
				<div><span class="f12">预计成熟：</span>{{record.ripe_time || '-'}}</div>
			</template>
			<template #status="{record}">
				<a-dropdown>
				    <a-tag :color="$status.getPlantStatusBg(record)">
				    	{{$status.getPlantStatus(record)}}
						<i class="ri-arrow-down-s-line"></i>
				    </a-tag>
				    <template #overlay>
						<a-menu>
							<a-menu-item @click="changePlantStatus('sau',[record.id])">播种</a-menu-item>
							<a-menu-item @click="changePlantStatus('xiao',[record.id])">幼苗</a-menu-item>
							<a-menu-item @click="changePlantStatus('up',[record.id])">生长</a-menu-item>
							<a-menu-item>
								<com-plant-output-set
									:ids="record.id"
									@success="callbackFun"
								>成熟</com-plant-output-set>
							</a-menu-item>
							<a-menu-item @click="changePlantStatus('complete',[record.id])">完成</a-menu-item>
							<a-menu-item @click="changePlantStatus('withered',[record.id])">枯萎</a-menu-item>
						</a-menu>
				    </template>
				</a-dropdown>
			</template>
			<template #action="{record}">
				<a-space>
                    <com-plant-status scene="all" :land-id="record.land_id" :ids="record.id"></com-plant-status>
				</a-space>
			</template>
		</a-table>
	</div>
</template>
<script>
import comPlantOutputSet from '@/components/layout/land/com-plant-output-set.vue'
import { computed, reactive, toRefs } from 'vue'
import landModel from '@/api/land.js'
import comPlantStatus from "./com-plant-status"
export default{
	name:'com-plant-list',
	components:{
		comPlantOutputSet,
        comPlantStatus
	},
	props:{
		list:{
			type:[Object,Array],
			default:[]
		},
		selection:{
			type:Boolean,
			default:true
		}
	},
	setup(props,context){
		const _d = reactive({
			pks:[],
		})

		const getTotalPrice = computed(()=>{
			return function(area,price){
				return (area * price).toFixed(2)
			}
		})

		const callbackFun = ()=>context.emit("success",true)
		const changePlantStatus = (op,id)=>landModel.setPlantStatus(op,id,()=>callbackFun())

		return{
			...toRefs(_d),
			changePlantStatus,
			callbackFun,
			getTotalPrice
		}
	},
}
</script>
<style lang="scss" scoped>
	.cpl-imgs{
		display: flex;
	}
</style>
