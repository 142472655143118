<template>
	<div class="com-plant-output-set">
		<div @click="show = true">
			<slot></slot>
		</div>
		<a-modal v-model:visible="show" title="预估产量" @ok="setOutPut" @cancel="show=false" width="400px">
			<a-input placeholder="预估产量" v-model:value="form.yield" addon-after="kg"></a-input>
		</a-modal>
	</div>
</template>

<script>
import { reactive, toRefs } from 'vue'
import landModel from '@/api/land.js'
export default{
	name:'com-plant-output-set',
	props:{
		ids:{
			type:[Object,Array,Number]
		},
	},
	setup(props,context){
		const _d = reactive({
			show:false,
			form:{
				plant_id:null,
				yield:"",
			}
		})
		function setOutPut(){
			let form = _d.form, ids = props.ids
			
			if( typeof ids == 'object' ){
				let _ids = []
				if( typeof ids[0] == 'object' ){
					ids.forEach(item=>{
						_ids.push(item.id)
					})
					form.plant_id = _ids
				}else{
					form.plant_id = ids
				}
			}else{
				form.plant_id = [ids]
			}
			//先将种植设为已成熟状态，再设置种植的预估产量
			landModel.setPlantStatus('ripe',form.plant_id,()=>{
				if(form.yield > 0){
					landModel.setPlantOrderYield(form.plant_id,form.yield,()=>{
						_d.show =false
						context.emit("success")
					})
					return
				}
				_d.show =false
				context.emit("success")
			})
		}
		return{
			...toRefs(_d),
			setOutPut
		}
	}
}
</script>

<style>
</style>
